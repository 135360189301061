import React, { useState, useEffect } from 'react';

function Dev() {

return (
  <section>
    <video src="../videos/future.mp4" autoplay loop muted></video>
    <h2>TEST</h2>
  </section>

  )
}

export default Dev;
