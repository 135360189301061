import React, {useState} from 'react';
import Video from '../../videos/future.mp4';
import { VideoTextBig, VideoTextSmall, HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, BigFont, BigFontMotto, WordTitle, WordBigger, WordSmaller, WordMotto, WordBigger2 } from './HeroElements';
import { Button } from '../ButtonElement';
import { animateScroll as scroll } from 'react-scroll';
import Breath from './Breath';

const HeroSection = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    const toggleBottom = () => {
        scroll.scrollToBottom();
    };

    return (
        <HeroContainer id='home'>
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
          <Breath />
             </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
