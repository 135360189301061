import styled from 'styled-components';
import { MdKeyboardArrowRight , MdArrowForward } from 'react-icons/md';

export const VideoTextBig = styled.div`
  padding-top: 5rem;
  position: absolute;
  inset: 0;
  place-items: center;
  text-align: center;
  background-color: black;
  color: white;
  font-weight: 900;
  font-size: 350px;
  font-family: sans-serif;
  /* Mix Blend Mode does the all magic */
  mix-blend-mode: multiply;
  user-select: none;
  -webkit-text-stroke-width: 10px;
  -webkit-text-stroke-color: black;

  @media screen and (max-width: 768px) {
    padding-top: 80%;
    position: absolute;
    inset: 0;
    place-items: center;
    text-align: center;
    background-color: black;
    color: white;
    font-weight: 900;
    font-size: 75px;
    font-family: sans-serif;
    /* Mix Blend Mode does the all magic */
    mix-blend-mode: multiply;
    user-select: none;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: white;;
  }

  @media screen and (max-width: 480px) {
    padding-top: 60%;
    position: absolute;
    inset: 0;
    place-items: center;
    text-align: center;
    background-color: black;
    color: white;
    font-weight: 900;
    font-size: 75px;
    font-family: sans-serif;
    /* Mix Blend Mode does the all magic */
    mix-blend-mode: multiply;
    user-select: none;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: white;
  }
`;

export const TextBig = styled.div`
  position: absolute;
  place-items: center;
  display: grid;
  background-color: transparent;
  color: transparent;
  font-weight: 900;
  font-size: 200px;
  font-family: sans-serif;
  user-select: none;
  -webkit-text-stroke-width: 4px;
  -webkit-text-stroke-color: white;
`;

export const VideoTextSmall = styled.div`
  position: absolute;
  place-items: center;
  background-color: #000;
  color: #fff;
  font-weight: 900;
  font-size: 150px;
  font-family: sans-serif;
  /* Mix Blend Mode does the all magic */
  mix-blend-mode: multiply;
  user-select: none;
`;

export const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 960px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
                    linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 80%);
        z-index: 2;
    }
`;

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin-bottom: 50%;
    }

    @media screen and (max-width: 480px) {
        margin-bottom: 50%;
    }
`;

export const HeroH1 = styled.h1`
    color: #fff;
    font-size: 48px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const BigFont = styled.h1`
    color: white;
    font-size: 510px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 130px;
    }

    @media screen and (max-width: 480px) {
        font-size: 85px;
    }
`;

export const WordTitle = styled.h1`
    display: flex;
    color: white;
    font-size: 220px;
    text-align: left;

    @media screen and (max-width: 768px) {
        font-size: 80px;
    }

    @media screen and (max-width: 480px) {
        font-size: 75px;
    }
`;

export const WordBigger = styled.h1`
    color: white;
    font-size: 380px;
    text-align: center;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: white;

    @media screen and (max-width: 768px) {
        font-size: 85px;
        text-align: center;
    }

    @media screen and (max-width: 480px) {
        font-size: 75px;
        text-align: center;
    }
`;

export const WordBigger2 = styled.h1`
    color: transparent;
    font-size: 300px;
    text-align: center;
    -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: white;

    @media screen and (max-width: 768px) {
        font-size: 85px;
        text-align: center;
        -webkit-text-stroke-width: 1px;
    }

    @media screen and (max-width: 480px) {
        font-size: 75px;
        text-align: center;
        -webkit-text-stroke-width: 1px;
    }
`;

export const WordSmaller = styled.h1`
    color: #006666;
    font-size: 65px;
    text-align: center;
    background-color: white;
    padding: 1rem;

    @media screen and (max-width: 768px) {
        font-size: 45px;
        text-align: center;
        background-color: white;
        padding: 1rem;
        margin-top: 15rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 30px;
        text-align: center;
        background-color: white;
        margin-bottom: 4rem;
        margin: 0rem;
    }
`;

export const WordMotto = styled.h1`
    color: #006666;
    font-size: 20px;
    text-align: center;
    background-color: white;
    padding: 1rem;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        text-align: center;
        background-color: white;
        padding: 1rem;
        margin-top: 0rem;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
        text-align: center;
        background-color: white;
        margin-bottom: 4rem;
        margin: 0rem;
    }
`;

export const HeroP = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 22px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const BigFontMotto = styled.p`
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 22px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 30px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 30px;
`;
