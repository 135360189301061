export const homeObjOne = {
    id: 'howdoesitwork',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    buttonDisabled: true,
    topLine: 'HOW DOES IT WORK?',
    headline: 'This is how easy it is!',
    description: 'Just add your profile and we do the matching. If you have already added your profile and want to update it, just add a new one with the same email.',
    description2: 'Begin to press "Add Profile" in the top-menu.',
    buttonLabel: 'Add Your Profile Now',
    imgStart: false,
    img: require('../../images/howdoesitwork.png').default,
    alt: 'How Does It Work?',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjFour = {
    id: 'contact',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    buttonDisabled: true,
    topLine: 'Contact Us',
    headline: '',
    description: 'Här kommer det att vara ett formulär...',
    buttonLabel: 'Back To Top',
    imgStart: false,
    img: require('../../images/contactus.png').default,
    alt: 'Contact Us',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjThree = {
    id: 'ourstory',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    buttonDisabled: true,
    topLine: 'OUR STORY',
    headline: 'Our Story',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttonLabel: 'Contact Us',
    imgStart: false,
    img: require('../../images/ourstory.png').default,
    alt: 'Our Story',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjTwo = {
    id: 'addprofile',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    buttonDisabled: true,
    topLine: 'ADD PROFILE',
    headline: 'Add Profile',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttonLabel: 'Contact Us',
    imgStart: false,
    img: require('../../images/addprofile.png').default,
    alt: 'Our Story',
    dark: true,
    primary: true,
    darkText: false,
};
