import React from 'react';
import { SidebarContainer,
        Icon,
        CloseIcon,
        SidebarWrapper,
        SidebarMenu,
        SidebarLink,
        SidebarLinkGreen,
        SideBtnWrap,
        SidebarRoute,
        SideLogo,
        SideFooter,
        TitleWord,
        TitleGreen
        } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
            <SideLogo><TitleGreen>FUTURE</TitleGreen></SideLogo>
            <SideFooter><TitleWord>Leave No<TitleGreen> TALENT </TitleGreen>Untapped</TitleWord></SideFooter>
                <SidebarMenu>
                    <SidebarLink to='howdoesitwork' onClick={toggle}>
                        HOW DOES IT WORK?
                    </SidebarLink>
                    <SidebarLinkGreen to='addprofile' onClick={toggle}>
                        ADD PROFILE
                    </SidebarLinkGreen>
                    <SidebarLink to='ourstory' onClick={toggle}>
                        OUR STORY
                    </SidebarLink>
                    <SidebarLink to='contact' onClick={toggle}>
                        CONTACT
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    {/* <SidebarRoute to='/signin'>Sign In</SidebarRoute> */}
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
