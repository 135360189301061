import React from 'react';
import styled, { keyframes } from 'styled-components';
import Video from '../../videos/future.mp4';
import { VideoTextBig, VideoTextSmall, HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, BigFont, BigFontMotto, WordTitle, WordBigger, WordSmaller, WordMotto, WordBigger2 } from './HeroElements';
const Breathe = () => {
 return (
   <Bounce>
      <WordBigger>FUTURE</WordBigger>
      <WordBigger2>TALENT</WordBigger2>
   </Bounce>
 )
}

export default Breathe;

const breatheAnimation = keyframes`
  0% { animation-timing-function: ease-in; opacity: 1; transform: translateY(-45px); }
  24% { opacity: 0.4; }
  40% { opacity: 0.5; animation-timing-function: ease-in; transform: translateY(-24px); }
	65% { opacity: 0.6; animation-timing-function: ease-in; transform: translateY(-12px); }
  82% { opacity: 0.7;animation-timing-function: ease-in; transform: translateY(-6px); }
	93% { opacity: 0.8; animation-timing-function: ease-in; transform: translateY(-4px); }
	25%,
	55%,
	75%,
	87% { opacity: 0.9;animation-timing-function: ease-out; transform: translateY(0px); }
  100% { opacity: 1; animation-timing-function: ease-out; opacity: 1; transform: translateY(0px); }
`
const Bounce = styled.div`
 background-color: transparent;
 animation-name: ${breatheAnimation};
 animation-duration: 2s;
 animation-iteration-count: 1;

 @media screen and (max-width: 768px) {
     animation-name: '';
 }

 @media screen and (max-width: 480px) {
     animation-name: '';
 }
`
