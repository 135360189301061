import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavBtn,
    NavBtnLink,
    NavlinkColor
} from './NavbarElements';

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false)

    //when scroll past 80px, trigger header background
    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    };

    return (
        <>
            <IconContext.Provider value={{ color: "#fff"}}>
                <Nav scrollNav={scrollNav}>
                    <NavbarContainer>
                        <NavLogo to="/" onClick={toggleHome}>Future Talent</NavLogo>
                        <MobileIcon onClick={toggle}>
                            <FaBars />
                        </MobileIcon>
                        <NavMenu>
                            <NavItem>
                                <NavLinks to="howdoesitwork"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={-80}
                                >HOW DOES IT WORK?</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavlinkColor to="addprofile"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={-80}>ADD PROFILE</NavlinkColor>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="ourstory"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={-80}>OUR STORY</NavLinks>
                            </NavItem>
                            <NavItem>
                                <NavLinks to="contact"
                                smooth={true}
                                duration={1000}
                                spy={true}
                                exact='true'
                                offset={-80}>CONTACT</NavLinks>
                            </NavItem>
                        </NavMenu>
                        <NavBtn>
                            {/* <NavBtnLink to="/signin">Sign In</NavBtnLink> */}
                        </NavBtn>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
